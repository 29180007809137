<template>
  <div class="MERCH cloudGood">
    <div class="merch-card scorr-roll">
      <!-- 搜索列 -->
      <el-form ref="ycApi" :model="ycApi" :inline="true">
        <el-form-item prop="">
          <el-input
            placeholder="商品名称/通用名/商品编号"
            v-model="ycApi.name"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="">
          <el-input
            placeholder="商品资料库编号"
            v-model="ycApi.platformGoodsId"
            clearable
          ></el-input>
        </el-form-item>
        <el-form-item prop="">
          <el-input
            clearable
            placeholder="批准文号"
            v-model="ycApi.approval_number"
          ></el-input>
        </el-form-item>
        <el-form-item prop="">
          <el-input
            clearable
            placeholder="生产厂家"
            v-model="ycApi.manufacturer"
          ></el-input>
        </el-form-item>
        <el-form-item prop="">
          <el-select
            clearable
            v-model="ycApi.supplier_id"
            placeholder="选择供应商"
            filterable
            remote
            reserve-keyword
            :remote-method="remoteMethod"
          >
            <el-option
              v-for="item in businessList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="">
          <el-cascader
            placeholder="选择商品分类"
            v-model="cascaderValue"
            :options="BasissCopeData.inquiregoods_categoryList"
            :props="propsData"
            @change="cascaderhandleChange"
            clearable
          ></el-cascader>
        </el-form-item>
        <el-form-item prop="">
          <el-select
            clearable
            v-model="ycApi.scope_id"
            placeholder="选择商品经营类别"
            filterable
          >
            <el-option
              v-for="item in BasissCopeData.Newbusiness_scope_id"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item prop="">
          <el-select clearable v-model="ycApi.for_sale" placeholder="选择状态">
            <el-option
              v-for="item in andOption"
              :key="item.type"
              :label="item.name"
              :value="item.type"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="getList1"> 查 询 </el-button>
          <el-button type="primary" @click="reset"> 重置查询条件 </el-button>
          <el-button type="primary" @click="getgoodsInfoexported(ycApi)">
            导出云仓商品
          </el-button>
        </el-form-item>
      </el-form>
      <!-- 列表 -->
      <div
        :style="`height: ` + mainHright + `px`"
        class="merch-table scorr-roll"
      >
        <div>
          <el-table
            :data="YcData.data"
            style="width: 100%"
            :header-cell-style="{ background: '#F2F3F5' }"
            border
          >
            <el-table-column prop="address" label="商品图片" align="center">
              <template slot-scope="{ row }">
                <div class="img-box">
                  <el-image
                    style="width: 50px; height: 50px"
                    :src="handleImageError(row.picture)"
                    :preview-src-list="[handleImageError(row.picture)]"
                  >
                  </el-image>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="商品信息"
              align="center"
              width="320"
            >
              <template slot-scope="{ row }">
                <div class="goods-message">
                  <div class="span-box">
                    <div class="exceed">商品编号：{{ row.id || "/" }}</div>
                    <div class="exceed">商品名称：{{ row.name || "/" }}</div>
                    <div class="exceed">
                      批准文号：{{ row.approvalNumber || "/" }}
                    </div>
                    <div class="exceed">
                      生产厂家：{{ row.manufacturer || "/" }}
                    </div>
                    <div class="exceed">
                      规格/包装：{{ row.specification || "/" }}
                    </div>
                    <div @click="goRouter(row)" class="exceed number">
                      商品资料库编号：
                      <div>
                        {{ row.platformGoodsId || "/" }}
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="经营类别"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.typeString || "/" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="商品分类"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div>
                  <span v-for="(v, i) in row.categoryString" :key="i"
                    >{{ v }}<br
                  /></span>
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="供应商"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.enterprise || "/" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="销售价（元）"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.sellingPrice || "/" }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="平台手续费"
              align="left"
              width="150"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  手续费：{{ "￥" + row.platformHandlingPrice || "/" }}
                  <br />
                  手续费比例：{{ row.platformHandlingRatio || "/" }}%
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="状态"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">{{ row.forSale | forSale }}</div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="自动下架原因"
              align="center"
              show-overflow-tooltip
            >
              <template slot-scope="{ row }">
                <div class="exceed">
                  {{ row.automaticSelling == 1 ? row.remark : "/" }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="address"
              label="操作"
              align="center"
              width="170"
            >
              <template slot-scope="{ row }">
                <div class="userBtn">
                  <div
                    @click="toMessage(row)"
                    v-if="allJurisdiction.cloudGoodMessage43"
                  >
                    详情
                  </div>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </div>
      <!-- 分页 -->
      <div class="merch-page">
        <el-pagination
          v-if="YcData.total != 0"
          background
          @current-change="handleCurrentChange"
          :current-page="YcData.per_page"
          layout="total, prev, pager, next, jumper"
          :total="YcData.total"
        >
        </el-pagination>
      </div>
    </div>
  </div>
</template>

<script>
import { downLoadZip } from "@/utils/zipdownload";
import { createNamespacedHelpers } from "vuex";
import { categoryList } from "@/utils/index";
// import { downLoadZip } from "../../utils/zipdownload";
const commonIndex = createNamespacedHelpers("commonIndex"); //vuex公共库
const Basics = createNamespacedHelpers("Basics");
const merchantCenterList = createNamespacedHelpers("merchantCenterList");

const { mapState, mapActions } = createNamespacedHelpers(
  "Goods",
  "commonIndex"
); //vuex公共库
export default {
  data() {
    return {
      searchData: {},
      activeName: 0,
      IsAddEnterprise: false,
      IsAddqualification: false,
      form: {},
      value: [],
      options: [],
      cascaderOptions: [],
      cascaderValue: "",
      ycApi: {
        page: 1,
        name: "",
        platformGoodsId: "",
        approval_number: "",
        manufacturer: "",
        supplier_id: "",
        scope_id: "",
        for_sale: "",
      },
      YcData: {
        total: 0,
        per_page: 1,
        data: [],
      },
      propsData: {
        value: "id",
        label: "category_name",
        children: "son",
      },
      // 审核
      andOption: [
        {
          name: "未上架",
          type: 0,
        },
        {
          name: "已上架",
          type: 1,
        },
        {
          name: "已下架",
          type: 2,
        },
        {
          name: "自动下架",
          type: 3,
        },
      ],
    };
  },
  filters: {
    forSale(v) {
      switch (v) {
        case 0:
          return "商品未上架";
        case 1:
          return "已上架";
        case 2:
          return "商品下架";
        case 3:
          return "自动下架";
      }
    },
  },
  computed: {
    ...commonIndex.mapState([
      "AreaList",
      "userType",
      "mainHright",
      "BasissCopeData",
      "FinilsCategoryList",
      "allJurisdiction",
      "detailsSeach",
    ]),
    ...merchantCenterList.mapState(["businessList"]),
  },
  created() {
    let router = this.$router.history.current.path.substr(1);
    if (this.detailsSeach[router]) {
      this.ycApi = this.detailsSeach[router];
      this.cascaderValue = this.detailsSeach[router].cascaderValue;
      this.YcData.per_page = this.detailsSeach[router].page;
    }
    this.getList();
    this.getBusinessList();

    // this.cgOptions();
    // this.cascaderOptions=this.FinilsCategoryList
  },
  mounted() {},
  methods: {
    //
    ...merchantCenterList.mapActions(["getBusinessList"]),
    ...commonIndex.mapMutations(["changeSeach"]),
    ...mapActions([
      "getgoodsInfolist",
      "getgoodsInfoexport",
      "getunclassified",
    ]),
    goRouter(row) {
      this.$router.push({
        name: "商品资料库",
        params: { id: row.platformGoodsId },
      });
    },
    remoteMethod(query) {
      this.getBusinessList({ name: query });
    },
    getList1() {
      this.ycApi.page = 1;
      this.YcData.per_page = 1;
      this.getList();
    },
    //重置搜索条件
    reset() {
      Object.assign(this.ycApi, this.$options.data().ycApi);
      this.ycApi.category_id = null;
      // let panel = this.$refs.cascader.panel
      this.ycApi.page = 1;
      this.YcData.per_page = 1;
      //   panel.cascaderValue = []
      //    panel.cascaderOptions = []
      //   panel.syncActivePath()
      this.cascaderValue = [];
      this.getList();
    },
    // 获取列表
    async getList() {
      let data = await this.getgoodsInfolist(this.ycApi);
      //console.log(data);
      let router = this.$router.history.current.path.substr(1);
      let save = { ...this.ycApi };
      save.cascaderValue = this.cascaderValue;
      save.router = router;
      this.changeSeach(save);
      if (data) {
        data.data.data.forEach((v) => {
          let categoryArr = [];
          if (v.categoryString) {
            v.categoryString.forEach((v1) => {
              let category = "";
              if (v1.length > 0) {
                v1.forEach((v2, i2) => {
                  if (i2 == v1.length || i2 == 0) {
                    category = category + v2;
                  } else {
                    category = category + ">" + v2;
                  }
                });
              }
              categoryArr.push(category);
            });
          }
          v.categoryString = categoryArr;
        });
        this.YcData.data = data.data.data;
        this.YcData.total = data.data.total;
      }
    },
    // 调整次级联动结构格式
    cgOptions() {
      setTimeout((v) => {
        this.cascaderOptions = categoryList(this.FinilsCategoryList);
      }, 1000);
    },
    cascaderhandleChange(value) {
      this.ycApi.category_id = value[value.length - 1];
    },
    handleCurrentChange(e) {
      this.YcData.per_page = e;
      this.ycApi.page = e;
      this.getList();
    },
    async getgoodsInfoexported(ycApi) {
      // let res = this.getunclassified(this.ycApi);
      // downLoadZip("/goods/goodsInfo/export", "xlsx", "未分类品种列表");
      downLoadZip(
        `/goods/goodsInfo/export`,
        "xlsx",
        "云仓商品列表",
        this.ycApi,
        "php",
        "get"
      );
    },
    // tag标签切换
    handleClick(tab, event) {
      //console.log(tab, event);
    },
    toMessage(row) {
      this.$router.push({ path: "/cloudGoodMessage", query: { id: row.id } });
    },
  },
};
</script>
<style lang="scss" scoped>
.dio-input {
  .el-input {
    width: 300px !important;
  }
  .el-upload--picture-card {
    width: 100px;
    height: 100px;
    line-height: 100px;
  }
}
.el-tabs__item.is-active {
  color: #06b7ae;
}
.el-tabs__item:hover {
  color: #06b7ae;
}
.el-tabs__active-bar {
  background-color: #06b7ae;
}
.el-input-group__prepend {
  padding: 0px 0px 0px 5px !important;
}
.el-input__inner {
  border-radius: 4px !important;
}
.el-range-separator {
  width: 20% !important;
}
</style>
<style lang="scss" scoped>
.userBtn {
  display: flex;
  justify-content: center;
  div {
    margin-left: 5px;
    color: #1abca9;
    cursor: pointer;
  }
}
.MERCH {
  width: 100%;
  height: 100%;
  .merch-card {
    width: 100%;
    max-height: 100%;
    padding: 20px;
    padding-top: 10px;
    border-radius: 4px;
    box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
    background: #ffffff;
    .merch-tag {
      width: 100%;
      height: 40px;
    }
    // 搜索行样式
    .merch-search {
      margin-top: 1%;
      width: 100%;
      .search-box {
        display: flex;
        flex-wrap: wrap;
      }
      .sch-1 {
        margin-top: 10px;
        width: 18%;
        min-width: 200px;
        display: flex;

        .sch-title {
          width: 40%;
          min-width: 80px;
          height: 100%;
          font-size: 13px;
          background-color: #f5f7fa;
          color: #909399;
          border: 1px solid #dcdfe6;
          border-right: none;
          border-radius: 4px 0px 0px 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0px 0px 0px 5px !important;
        }
      }
      .sch-2 {
        margin-right: 2%;
      }
    }
    .merch-table {
      margin-top: 1%;
      width: 100%;
    }
    .merch-page {
      margin-top: 10px;
      width: 100%;
      display: flex;
      align-content: center;
      justify-content: right;
    }
  }
  .goods-message {
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .img-box {
      width: 50px;
      height: 50px;
      background: rebeccapurple;
    }
    .span-box {
      margin-left: 10px;
      width: 250px;
      // display: flex;
      // flex-wrap: wrap;
      // justify-content: left;

      div {
        // width: 100%;
        text-align: left;
      }
      .number {
        display: flex;
        align-items: center;
        color: red;
        div {
          border-bottom: 1px solid red;
        }
      }
    }
  }
}
</style>
